import * as React from 'react';
import {FieldArray} from 'formik';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {IService, IYearCatalogs} from "../../interfaces";
import Service from "./Service";
import {useContext} from "react";
import {YearCatalogsContext} from "../../contexts/YearCatalogsContext";

interface Props {
    services: IService[];
    setFieldValue: any;
    submitForm: any;
    locale: string;
    title: string;
    bgColor?: string;
    id: string;
}

const Services: React.FunctionComponent<Props> = props => {
    const {services, setFieldValue, submitForm, bgColor, title, id} = props;
    const catalogs = useContext<IYearCatalogs>(YearCatalogsContext)

    const onDragEnd = (result, move) => {
        const { source, destination } = result;
        if (!destination) return;
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        )
            return;

        // move code to destination
        move(source.index, destination.index);
        submitForm();
    };

    return (
        <div className="codes_box" id={"services_box_" + id} style={{backgroundColor: bgColor || '#f9f0d7'}}>
            <div className="row">
                <div className="col-sm-12">
                    <h4>{title}
                        <small className="text-secondary ms-2" style={{fontSize: '0.5em'}}>
                            LKAAT {catalogs.serviceCatalog}
                        </small></h4>
                </div>
            </div>
            <div>
                <FieldArray
                    name="services"
                    render={arrayHelpers => (
                        <DragDropContext onDragEnd={result => {onDragEnd(result, arrayHelpers.move)}}>
                            <Droppable droppableId="tarpo-dropzone">
                                {(provided) => (
                                    <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                    >
                                        {services && services.map((service, index) => (
                                            <Draggable key={index} draggableId={'service' + index} index={index}>
                                                {(provided) => (
                                                    <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <Service
                                                            index={index}
                                                            service={service}
                                                            setFieldValue={setFieldValue}
                                                            submitForm={submitForm}
                                                            key={index}
                                                            arrayHelpers={arrayHelpers}
                                                        />
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        <div className="row pb-2">
                                            <div className="col-sm-12">
                                                <button
                                                    type="button"
                                                    onClick={() => arrayHelpers.push(
                                                        {
                                                            code: "",
                                                            number: 1,
                                                            active: true
                                                        })}
                                                    className="pull-right btn btn-secondary btn-sm pe-2 ps-2"
                                                >
                                                    <i className="fa fa-plus"/>
                                                </button>
                                            </div>
                                        </div>
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    )}
                />
            </div>
        </div>
    );
};

export default Services
